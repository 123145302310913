@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hero-section {
  padding-top: 50px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #f6f8fb;
}

.hero-container {
  width: 1180px;
  height: auto;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.hero-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #1a202c;
}

.hero-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #718096;
}

.hero-button-container {
  margin-top: 40px;
  display: flex;
  column-gap: 20px;
}

.hero-button-right {
  border: none;
  outline: none;
  padding: 15px 30px;
  box-sizing: border-box;
  border-radius: 7px;
  background-color: #1a202c;
  cursor: pointer;
}

.hero-button-right > span {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #f7fafc;
  text-decoration: none;
}

.hero-button-right:hover {
  opacity: 0.9;
}

.hero-button-left > span {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #1a202c;
  text-decoration: none;
}

.hero-button-left {
  border: 1px solid #cbd5e0;
  outline: none;
  padding: 15px 30px;
  box-sizing: border-box;
  border-radius: 7px;
  background-color: #ffffff;
  cursor: pointer;
}

.hero-button-left:hover {
  background-color: #f6f8fb;
}

.hero-image {
  width: 971px;
  height: auto;
  margin-top: 70px;
  margin-bottom: -300px;
}

.image-top-container {
  width: 100%;
  height: 50px;
  background-color: #f1f2f4;
  border-radius: 10px 10px 0 0;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-buttons {
  width: 10%;
  height: 60%;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 10px;
}

.button-red {
  width: 16px;
  height: 16px;
  background-color: #f33d3d;
  border-radius: 10px;
}

.button-yellow {
  width: 16px;
  height: 16px;
  background-color: #feb932;
  border-radius: 10px;
}

.button-green {
  width: 16px;
  height: 16px;
  background-color: #04b079;
  border-radius: 10px;
}

.search-bar {
  width: 85%;
  height: 65%;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  padding: 5px 15px;
}

.domain-text-one {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #718096;
}

.domain-text-two {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #4a5568;
}

.image-bottom-container {
  width: 100%;
  height: auto;
  border-left: 1px solid #cbd5e0;
  border-right: 1px solid #cbd5e0;
  border-bottom: 1px solid #cbd5e0;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  background-color: white;
  box-shadow: 0px 4px 10px 0px rgba(19, 22, 34, 0);
}

.amaMobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .hero-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .hero-container {
    width: 100%;
  }

  .hero-title-container {
    row-gap: 0;
  }

  .hero-title {
    font-size: 30px;
    margin-bottom: 16px;
  }

  .hero-subtitle {
    font-size: 16px;
    line-height: 150%;
  }

  .hero-image {
    width: 100%;
    margin-bottom: -150px;
  }

  .image-top-container {
    height: 30px;
  }

  .search-bar {
    width: 80%;
    height: 70%;
    padding: 0 7px;
    display: flex;
    align-items: center;
    border-radius: 6px;
  }

  .domain-text-one {
    font-size: 10px;
  }

  .domain-text-two {
    font-size: 10px;
  }

  .menu-buttons {
    width: 15%;
    column-gap: 0;
  }

  .button-red {
    width: 8px;
    height: 8px;
    margin-right: 6px;
  }

  .button-yellow {
    width: 8px;
    height: 8px;
    margin-right: 6px;
  }

  .button-green {
    width: 8px;
    height: 8px;
  }

  .hero-button-container {
    flex-direction: column;
    row-gap: 0px;
    width: 100%;
    margin-top: 32px;
  }

  .hero-button-left {
    width: 100%;
  }

  .hero-button-right {
    width: 100%;
    margin-top: 10px;
  }

  .image-bottom-container {
    justify-items: center;
    align-items: center;
    padding-top: 50px;
  }

  .amaDesktop {
    display: none;
  }

  .amaMobile {
    display: block;
  }
}
