.error-section {
  width: 100vw;
  height: 100vh;
  background-color: #f6f8fb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-container {
  width: 500px;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-container > img {
  margin-bottom: 30px;
}

.error-page-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: #1a202c;
  margin-bottom: 10px;
}

.error-page-subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #4a5568;
  margin-bottom: 30px;
  text-align: center;
  line-height: 150%;
}

.error-container > a {
  width: 100%;
}

.error-page-button {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: none;
  background-color: #eff1f6;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
}

.error-page-button:hover {
  background-color: #131726;
  color: white;
}

@media only screen and (max-width: 768px) {
  .error-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .error-container {
    width: 100%;
  }

  .error-page-title {
    text-align: center;
  }
}
