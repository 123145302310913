.answers-container {
  width: 100%;
  height: auto;
  margin-top: 50px;
}

.answer-box {
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.question {
  margin-bottom: -1%;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #1a202c;
  line-height: 150%;
}

.answer {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #2d3748;
  line-height: 150%;
}

.createdAt {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #718096;
  margin-top: 15px;
  width: 100%;
}

.emptystate-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.empty-title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #1a202c;
  margin-bottom: -1%;
}

.empty-subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #718096;
}

.show-more-button {
  width: 100%;
  height: 50px;
  background-color: #131622;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 30px;
  cursor: pointer;
}

.showmore-button-text {
  color: #f7fafc;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
}

.logo-stamp-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.powered-text {
  color: #718096;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.logo-stamp {
  color: #718096;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  margin-top: 1px;
}

.stamp-container {
  display: flex;
  justify-content: center;
  column-gap: 3px;
  margin-left: 10px;
}

.logo-stamp:hover {
  cursor: pointer;
  color: #131622;
}

.loading-wrapper {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .answer {
    width: 100%;
    word-wrap: break-word;
  }
}
