@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

.root {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
}

.container {
  width: 640px;
  height: auto;
  position: relative;
  margin-top: 6%;
  margin-bottom: 10%;
}

.question-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 36px;
  color: #1a202c;
  margin-bottom: -2%;
}

.subtext {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 22px;
  color: #718096;
}

.textarea-form {
  width: 100%;
  height: 150px;
  max-width: 100%;
  border: 1px solid #e4e4e7;
  outline-color: #52525b;
  border-radius: 7px;
  padding: 15px;
  resize: vertical;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #f7fafc;
}

::placeholder {
  color: #718096;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;
}

.submit-button {
  width: 106px;
  height: 40px;
  margin-top: 15px;
  border-radius: 4px;
  background-color: #131622;
  border: none;
  cursor: pointer;
  outline: none;
}

.submit-button:hover {
  opacity: 0.8;
}

.button-text {
  color: #f7fafc;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #f4f4f5;
  margin-top: 50px;
}

.success-message {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -40px;
}

.success-message-text {
  color: #004e3d;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.success-image {
  margin-right: 5px;
}

@media only screen and (max-width: 768px) {
  .root {
    width: 100%;
  }

  .container {
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
    margin-top: 50px;
  }

  .title {
    font-size: 26px;
    margin-bottom: -2%;
  }

  .subtext {
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 30px;
  }

  .textarea-form {
    height: 130px;
    -webkit-appearance: none;
  }

  .textarea-form:focus {
    outline: none;
    border: 2px solid #1a202c;
  }

  .success-message {
    margin-bottom: 0px;
    align-items: flex-start;
  }

  .submit-button {
    width: 100%;
  }
}
