@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.full-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0px 0px 10px 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 6px -3px #cbd5e0;
}

.top-box {
  width: 100%;
  height: auto;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.badge {
  width: 95px;
  height: 20px;
  background-color: #fcf5ba;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: #98774d;
}

.question {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.question-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #131726;
}

.textarea-answer {
  width: 100%;
  min-height: 175px;
  border: 1px solid #e4e4e7;
  outline-color: #52525b;
  border-radius: 7px;
  padding: 15px;
  resize: vertical;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #f9fafc;
}

.display-off {
  display: none;
}

.bottom-box {
  width: 100%;
  height: 30%;
  background: #f9fafc;
  border-radius: 0px 0px 10px 10px;
  padding: 0px 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.createdAt {
  width: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
}

.received-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 10px;
  color: #718096;
  letter-spacing: 1px;
}

.date-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #131726;
  margin-top: 5px;
}

.answer-button-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answer-button {
  width: 100px;
  height: 30px;
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: #131726;
  cursor: pointer;
}

.answer-button:hover {
  opacity: 0.9;
}

.cancel-button {
  width: 80px;
  height: 30px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d1d5da;
  background-color: white;
  cursor: pointer;
  margin-right: 10px;
}

.send-button {
  width: 100px;
  height: 30px;
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: #131726;
  cursor: pointer;
}

.send-icon {
  margin-right: 5px;
}

.cancel-button:hover {
  background-color: #f7fafc;
}

.cancel-button-text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #131726;
  margin-bottom: 1px;
}

.button-inside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #f7fafc;
  margin-bottom: 1px;
}

.pen-icon {
  margin-right: 5px;
}

.loading-wrapper {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.binIcon-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.binIcon-wrapper:hover {
  background-color: #fed7d7;
}

@media only screen and (max-width: 768px) {
  .textarea-answer {
    -webkit-appearance: none;
  }

  .textarea-answer:focus {
    outline: none;
    border: 2px solid #1a202c;
  }
}
