@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
}

.dashboard-root {
  width: 100%;
  height: auto;
  background-color: #edf2f7;
  position: relative;
  padding-bottom: 40px;
}

/* Header Section */

.displayNone {
  display: none;
}

.dashboard-container {
  width: 900px;
  min-height: 800px;
  height: auto;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 60px;
}

.dashboard-nav {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.nav-left {
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-left:hover {
  opacity: 0.9;
}

.logo-text {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #131726;
  margin: 0;
  text-decoration: solid;
}

.nav-right {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 25px;
}

.nav-cancel-button {
  width: auto;
  height: auto;
  padding-right: 25px;
  border-right: 1px solid #4a5568;
}

.dashboard-upgrade-button {
  padding: 10px 20px;
  outline: none;
  border: none;
  background-color: #dfe3ec;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #131726;
}

.nav-text {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #131726;
  text-decoration: none;
  cursor: pointer;
}

.nav-text:hover {
  opacity: 0.8;
}

.margin-right {
  margin-right: 30px;
}

.dashboard-banner {
  width: 100%;
  height: auto;
  background-color: #131726;
  border-radius: 10px;
  margin-top: 30px;
  padding: 26px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dashboard-banner-title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #f7fafc;
  margin: 0;
}

.dashboard-banner-subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #cbd5e0;
  margin: 0;
  margin-top: 5px;
  line-height: 150%;
}

.dashboard-button-container {
  margin-top: 30px;
}

.button-widget {
  width: 175px;
  height: 40px;
  background-color: #f7fafc;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}
.button-widget:hover {
  opacity: 0.9;
}

.button-widget-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-dashboard {
  width: 196px;
  height: 40px;
  background-color: #212936;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-left: 20px;
  cursor: pointer;
}

.button-dashboard:hover {
  opacity: 0.8;
}

.button-widget-text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
  margin-left: 5px;
}

.button-dashboard-text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #edf2f7;
  margin-left: 5px;
}

/* Dashboard Section */

.dashboard-section {
  width: 100%;
  height: auto;
  margin-top: 70px;
}

.name-container {
  width: 100%;
  height: auto;
}

.dashboard-name {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #131726;
}

.options-container {
  width: 100%;
  height: auto;
  margin-top: 70px;
}

.option {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #131726;
  cursor: pointer;
  padding-bottom: 10px;
}

.selected-option {
  border-bottom: 2px solid #131726;
}

/* Question Section */

.question-section {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

/* Footer Section */

.footer-section {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
}

.footer-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-footer-container {
  display: flex;
  flex-direction: column;
}

.copyright-text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
  margin: 0;
}

.copyright-text-bottom {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
  margin: 0;
}

.copyright-container {
  display: flex;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
}

.left-footer-box {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.twitter-link {
  color: #2d3748;
  text-decoration: none;
  margin-left: 3px;
}

.footer-subtext {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
  margin: 0;
}

.central-footer-box {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

[data-reach-dialog-content] {
  width: 600px !important;
  height: auto !important;
  padding: 0px !important;
  border-radius: 10px;
}

.modal-top-container {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #cbd5e0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-top-container > img {
  margin-right: 30px;
}

.modal-bottom-container {
  padding: 40px;
  box-sizing: border-box;
}

.paywall-modal-container {
  width: 100%;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
}

.paywall-top {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 30px;
}

.paywall-badge {
  width: auto;
  padding: 4px 15px;
  background-color: #dcecfd;
  border-radius: 30px;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #30519f;
  letter-spacing: 1px;
}

.paywall-modal-price {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 40px;
  color: #1a202c;
}

.paywall-modal-price > span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #718096;
  margin-left: 5px;
}

.paywall-modal-subtext {
  margin-top: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #718096;
  line-height: 150%;
}

.paywall-divider {
  width: 90%;
  height: 1px;
  background-color: #edf2f7;
}

.paywall-bottom {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
  border-top: 1px solid #edf2f7;
}

.paywall-modal-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.paywall-modal-item > span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #131726;
  margin-left: 15px;
}

.paywall-bottom > button {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: #eff1f6;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
}

.paywall-bottom > button:hover {
  background-color: #131726;
  color: white;
}

.paywall-cancel-text {
  width: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
  margin-top: 20px;
}

.cancel_subscription_modal {
  width: 100%;
  height: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.warning_icon_wrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fee5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.subscription_modal_title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #131726;
  text-align: center;
}

.subscription_modal_subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #4a5568;
  margin-top: 10px;
  line-height: 150%;
  text-align: center;
}

.subscription_modal_buttonOne {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: 1px solid #cbd5e0;
  background-color: white;
  border-radius: 6px;
  margin-top: 40px;
  margin-bottom: 8px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
}

.subscription_modal_buttonOne:hover {
  background-color: #f7fafc;
}

.subscription_modal_buttonTwo {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: #e02222;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #edf2f7;
}

.subscription_modal_buttonTwo:hover {
  background-color: #c61b1a;
}

.modal-bottom-container > h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #131726;
  margin: 0;
}

.modal-bottom-container > p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #4a5568;
  margin-top: 20px;
  line-height: 150%;
}

.modal-code-container {
  width: 100%;
  height: height;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #1d2b44;
  padding: 20px 0px 20px 20px;
  box-sizing: border-box;
  overflow: auto;
  white-space: nowrap;
}

.modal-code-container::-webkit-scrollbar {
  display: none;
}

.modal-contact-support > span {
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 400;
  font-size: 16px;
  color: #4162fa;
}

.modal-contact-support:hover {
  opacity: 0.8;
}

.embed-code-line {
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
}

.code-line-number {
  margin-right: 18px;
  color: #6c7d9b;
  font-weight: 400;
}

.code-line-comment {
  color: #4fd1c5;
  font-weight: 400;
}

.code-line-bracket {
  color: #9eb3d6;
}

.code-line-script {
  color: #f56565;
  margin-right: 8px;
}

.code-line-source {
  color: #f6ad55;
}

.code-line-link {
  color: #68d391;
}

.modal-button-wrapper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-button-left {
  width: 49%;
  height: auto;
  padding: 10px 0px;
  background-color: white;
  outline: none;
  border: 1px solid #cbd5e0;
  border-radius: 5px;
  cursor: pointer;
}

.modal-button-left > span {
  font-family: "Inter", sans-serif;
  font-weight: 5 00;
  font-size: 14px;
  color: #1a202c;
}

.modal-button-right:hover {
  opacity: 0.9;
}

.modal-button-right {
  width: 49%;
  height: auto;
  padding: 10px 0px;
  background-color: #1a202c;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-button-left:hover {
  background-color: #f6f8fb;
}

.modal-button-right > span {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #f7fafc;
}

.crossIcon {
  cursor: pointer;
}

.crossIcon:hover {
  opacity: 0.6;
}

.deleteQuestion-modal-wrapper {
  width: 100%;
  height: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #131726;
  text-align: center;
}

.deleteQuestion-modal-wrapper > span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #4a5568;
  margin-top: 10px;
  line-height: 150%;
  text-align: center;
}

.deleteQuestion-modal-buttonWrapper {
  width: 100%;
  margin-top: 40px;
}

.deleteQuestion-modal-cancelButton {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: 1px solid #cbd5e0;
  background-color: white;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
  margin-bottom: 10px;
}

.deleteQuestion-modal-cancelButton:hover {
  background-color: #f7fafc;
}

.deleteQuestion-modal-deleteButton {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: none;
  background-color: #c53030;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;
}

.deleteQuestion-modal-deleteButton:hover {
  background-color: #ea3e40;
}

@media only screen and (max-width: 768px) {
  .dashboard-container {
    width: 100%;
    padding: 30px 16px;
    box-sizing: border-box;
    min-height: 0px;
  }

  .dashboard-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-right {
    column-gap: 0px;
  }

  .stamp-image {
    width: 30px;
    margin-right: 10px;
  }

  .nav-text {
    font-size: 14px;
  }

  .logo-text {
    font-size: 20px;
    font-weight: 700;
  }

  .dashboard-banner {
    height: auto;
  }

  .dashboard-banner-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .dashboard-banner-subtitle {
    font-size: 16px;
    line-height: 150%;
  }

  .dashboard-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .button-widget {
    width: 100%;
    margin-bottom: 15px;
  }

  .button-widget-container {
    width: 100%;
  }

  .button-widget-container > span {
    font-size: 16px;
  }

  .button-dashboard {
    width: 100%;
    margin: 0;
    align-items: center;
  }

  .button-dashboard-container > span {
    font-size: 16px;
  }

  .footer-section {
    display: none;
  }

  [data-reach-dialog-content] {
    width: 95% !important;
    height: auto !important;
    padding: 0px !important;
    border-radius: 10px;
  }

  .modal-bottom-container {
    padding: 20px;
    box-sizing: border-box;
  }

  .nav-cancel-button {
    padding-right: 15px;
    margin-right: 15px;
  }
}
