@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.pricing-section {
  width: 100%;
  height: auto;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pricing-container {
  width: 1180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 75px;
}

.pricing-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.pricing-title-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #1a202c;
}

.pricing-subtitle-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
}

.pricing-grid {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 80px;
}

.pricing-element {
  width: auto;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pricing-element-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}

.pricing-offer-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: #1a202c;
}

.pricing-offer-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
}

.pricing-buttonContainer {
  width: 100%;
  margin-top: 30px;
}

.pricing-item {
  display: flex;
  column-gap: 10px;
  margin-top: 20px;
}

.pricing-item-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #1a202c;
}

.pricing-buy-button {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: #1a202c;
  cursor: pointer;
}

.pricing-buy-button:hover {
  opacity: 0.8;
}

.pricing-button-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #f7fafc;
}

.month {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #718096;
  margin-left: 5px;
}

.pricing-underBtn-text {
  width: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .pricing-section {
    margin-top: 100px;
  }

  .pricing-container {
    width: 100%;
    row-gap: 0px;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .pricing-grid {
    flex-direction: column;
    margin-top: 40px;
  }

  .pricing-element {
    width: 100%;
    margin-bottom: 30px;
    row-gap: 0px;
  }

  .pricing-title-text {
    margin-bottom: 16px;
  }

  .pricing-title-container {
    margin-bottom: 20px;
    row-gap: 0px;
  }

  .pricing-element-inner {
    row-gap: 0px;
  }

  .pricing-offer-title {
    margin-bottom: 10px;
  }

  .pricing-item {
    margin-top: 30px;
  }
}
