@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

.nav-section {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #f6f8fb;
}

.nav-container {
  width: 1180px;
  height: auto;
  margin-top: 30px;
}

.nav-inner-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav-right {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}

.nav-sign-wrapper {
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-left: 1px solid #4a5568;
}

.nav-sign-wrapper > img {
  margin-left: 5px;
  margin-top: 2px;
}

.nav-left {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.nav-button {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #1a202c;
  text-decoration: none;
  cursor: pointer;
}

.nav-button:hover {
  opacity: 0.8;
}

.nav-brand-name {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1a202c;
}

.return-home {
  text-decoration: none;
}

/* Mobile Responsiveness */

@media screen and (max-width: 768px) {
  .nav-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .nav-container {
    width: 100%;
  }

  .nav-inner-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-button {
    font-size: 14px;
  }

  .nav-brand-name {
    font-size: 20px;
    font-weight: 700;
    margin-left: 10px;
  }

  .nav-right {
    margin-top: 16px;
    column-gap: 0;
  }

  .nav-right > a {
    margin-right: 16px;
  }

  .nav-left > img {
    width: 30px;
  }

  .nav-left {
    column-gap: 0;
  }
}
