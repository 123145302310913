@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hiw-section {
  margin-top: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.hiw-container {
  width: 1180px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hiw-title-container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.hiw-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #1a202c;
}

.hiw-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
}

.feature {
  width: 100%;
  margin-top: 130px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.feature-text-container {
  max-width: 490px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
}

.hiw-feature-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #1a202c;
}

.hiw-number {
  width: 50px;
  height: 50px;
  background-color: #1a202c;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.hiw-number-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 28px;
  color: #f7fafc;
}

.hiw-feature-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
  line-height: 150%;
}

.extraFeatures-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  margin-top: 20px;
}

.extraFeatures-image-container {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: hsla(220, 26%, 14%, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.extraFeatures-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1a202c;
}

/* Mobile */

@media only screen and (max-width: 768px) {
  .hiw-section {
    margin-top: 350px;
  }

  .hiw-container {
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .hiw-title {
    margin-bottom: 20px;
  }

  .hiw-subtitle {
    text-align: center;
  }

  .feature-image-container {
    width: 100%;
  }

  .feature-image-container > img {
    width: 100%;
  }

  .feature {
    flex-direction: column;
    margin-top: 75px;
  }

  .feature-text-container {
    row-gap: 0px;
  }

  .hiw-number {
    margin-bottom: 30px;
  }

  .hiw-feature-title {
    margin-bottom: 16px;
  }

  .hiw-first {
    margin-bottom: 10px;
    margin-top: 40px;
  }

  .hiw-second {
    margin-bottom: 40px;
  }

  .hiw-swapped {
    flex-direction: column-reverse;
  }

  .hiw-space-feature {
    margin-bottom: 40px;
  }

  .extraFeatures-text {
    margin-left: 10px;
  }
}
