.landing-footer-section {
  margin-top: 150px;
  width: 100%;
  height: auto;
  background-color: #f6f8fb;
  border-top: 1px solid #cbd5e0;
  display: flex;
  justify-content: center;
}

.landing-footer-container {
  width: 1180px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.landing-footer-left {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.landing-title-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1a202c;
}

.landing-subtitle-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
}

.landing-twitter-link {
  font-weight: 500;
  color: #2d3748;
  cursor: pointer;
  text-decoration: none;
  margin-left: 5px;
}

.landing-footer-center {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.landing-top-row {
  display: flex;
  justify-content: center;
  column-gap: 5px;
}

.landing-footer-year {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
}

@media only screen and (max-width: 768px) {
  body {
    width: 100%;
  }

  .landing-footer-section {
    margin-top: 100px;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .landing-footer-container {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .landing-footer-left {
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    row-gap: 0px;
  }

  .landing-footer-left > h3 {
    margin-bottom: 5px;
  }

  .landing-footer-center {
    display: none;
  }

  .landing-footer-right {
    margin-bottom: 10px;
  }
}
