@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400;500;600;700;900&display=swap");

.embed-section {
  width: 100%;
  height: auto;
  margin-top: 150px;
  background-color: #f6f8fb;
  border-top: 1px solid #cbd5e0;
  border-bottom: 1px solid #cbd5e0;
  display: flex;
  justify-content: center;
  padding: 80px 0;
}

.embed-container {
  width: 1180px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
}

.embed-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.embed-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #1a202c;
}

.embed-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
}

.embed-code-fullContainer {
  width: 600px;
  height: auto;
  background-color: #1d2b44;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 20px;
  box-sizing: border-box;
}

.embed-code-container {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  position: relative;
  margin-top: -10px;
}

.outside-overflow {
  width: 100%;
  height: 20px;
  position: relative;
}

.clip-wrapper {
  position: absolute;
  right: 10px;
  top: -10px;
  width: 30px;
  height: 30px;

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clip-wrapper:hover {
  background-color: #283d58;
  cursor: pointer;
}

.embed-code-container::-webkit-scrollbar {
  display: none;
}

.embed-code-line {
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
}
.code-line-number {
  margin-right: 18px;
  color: #6c7d9b;
  font-weight: 400;
}

.code-line-comment {
  color: #4fd1c5;
  font-weight: 400;
}

.code-line-bracket {
  color: #9eb3d6;
}

.code-line-script {
  color: #f56565;
  margin-right: 8px;
}

.code-line-source {
  color: #f6ad55;
}

.code-line-link {
  color: #68d391;
}

.left-margin {
  margin-left: 15px;
}

.code-line-function {
  color: #ecc94b;
}

.code-line-key {
  color: #9eb3d6;
  margin-right: 5px;
}

.embed-demo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.embed-demo-button:hover {
  opacity: 0.8;
}

.embed-demo-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1a202c;
  cursor: pointer;
  text-decoration: none;
}

.arrowIcon {
  margin-top: 3px;
}

@media only screen and (max-width: 768px) {
  .embed-section {
    width: 100%;
    padding: 60px 16px;
    box-sizing: border-box;
  }

  .embed-container {
    width: 100%;
    row-gap: 0px;
  }

  .embed-title-container {
    row-gap: 0px;
  }

  .embed-title {
    text-align: center;
    margin-bottom: 16px;
  }

  .embed-subtitle {
    margin-bottom: 40px;
    text-align: center;
  }

  .embed-code-fullContainer {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 20px;
  }
}
