@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.answers-full-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0px 0px 10px 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 6px -3px #cbd5e0;
}

.answers-top-box {
  width: 100%;
  height: auto;
  min-height: 70%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.answers-bottom-box {
  width: 100%;
  height: auto;
  min-height: 70%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.answers-badge {
  width: 74px;
  height: 20px;
  background-color: #dbf4f5;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answers-badge-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: #3a6c73;
}

.answers-question {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.answers-question-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #131726;
  line-height: 25px;
}

.answers-answer {
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #4a5568;
  line-height: 25px;
}

.answers-bottom-box {
  width: 100%;
  height: 30%;
  background: #f9fafc;
  border-radius: 0px 0px 10px 10px;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.answers-createdAt {
  width: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
}

.answers-received-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 10px;
  color: #718096;
  letter-spacing: 1px;
}

.answers-date-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #131726;
  margin-top: 5px;
}
