.login-section {
  width: 100vw;
  height: 100vh;
  background-color: #f6f8fb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  width: 500px;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 10px;
}

.login-form-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #1a202c;
}

.login-form-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
  line-height: 150%;
  margin-top: 10px;
}

.login-form-input {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.login-email-label {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #4a5568;
  margin-bottom: 8px;
}

.login-password-label {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #4a5568;
  margin-top: 20px;
  margin-bottom: 8px;
}

.login-email-input {
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  outline: none;
  padding: 0px 10px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.login-password-input {
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  outline: none;
  padding: 0px 10px;
}

.login-password-input::placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.login-password-input:focus {
  border: 2px solid #1a202c;
}

.login-email-input:focus {
  border: 2px solid #1a202c;
}

.login-email-input::placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.login-submit-button {
  margin-top: 30px;
  outline: none;
  border: none;
  width: 100%;
  background-color: #1a202c;
  border-radius: 5px;
  padding: 14px 0px;
  cursor: pointer;
}

.login-submit-button:hover {
  opacity: 0.9;
}

.login-submit-button > span {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #f7fafc;
  margin-top: 40px;
}

.login-register-button {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-register-button > p {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
}

.register-button-redirect {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1a202c;
  text-decoration: underline;
  margin-left: 5px;
}

.register-button-redirect:hover {
  opacity: 0.8;
}

.login-error-container {
  margin-top: 30px;
  margin-bottom: -10px;
  width: 100%;
  height: auto;
  min-height: 40px;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: #fdf0f0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 5px;
  border-radius: 4px;
  border: 1px solid #9f2d2d;
}

.login-error-container > h3 {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #9f2d2d;
  line-height: 150%;
}

@media only screen and (max-width: 768px) {
  .login-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .login-container {
    width: 100%;
  }

  .login-form-container {
    width: 100%;
  }

  .login-error-container > img {
    margin-right: 5px;
  }

  .login-email-input {
    -webkit-appearance: none;
  }

  .login-password-input {
    -webkit-appearance: none;
  }
}
