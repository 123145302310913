@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.answers-container {
  width: 100%;
  height: auto;
  margin-top: 50px;
}

.answer-box {
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.question {
  margin-bottom: -1%;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #1a202c;
  line-height: 150%;
}

.answer {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #2d3748;
  line-height: 150%;
}

.createdAt {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #718096;
  margin-top: 15px;
  width: 100%;
}

.emptystate-container {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 100px;
}

.empty-title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #1a202c;
  margin-bottom: -1%;
}

.empty-subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #718096;
}

.show-more-button {
  width: 100%;
  height: 50px;
  background-color: #131622;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 30px;
  cursor: pointer;
}

.showmore-button-text {
  color: #f7fafc;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
}

.logo-stamp-container {
  width: 100%;
  margin-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.powered-text {
  color: #718096;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.logo-stamp {
  color: #718096;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  margin-top: 1px;
}

.stamp-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  grid-column-gap: 3px;
  -webkit-column-gap: 3px;
          column-gap: 3px;
  margin-left: 10px;
}

.logo-stamp:hover {
  cursor: pointer;
  color: #131622;
}

.loading-wrapper {
  width: 100%;
  height: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@media only screen and (max-width: 768px) {
  .answer {
    width: 100%;
    word-wrap: break-word;
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

.root {
  width: 100vw;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.container {
  width: 640px;
  height: auto;
  position: relative;
  margin-top: 6%;
  margin-bottom: 10%;
}

.question-form {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 36px;
  color: #1a202c;
  margin-bottom: -2%;
}

.subtext {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 22px;
  color: #718096;
}

.textarea-form {
  width: 100%;
  height: 150px;
  max-width: 100%;
  border: 1px solid #e4e4e7;
  outline-color: #52525b;
  border-radius: 7px;
  padding: 15px;
  resize: vertical;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #f7fafc;
}

::-webkit-input-placeholder {
  color: #718096;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;
}

:-ms-input-placeholder {
  color: #718096;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;
}

::placeholder {
  color: #718096;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;
}

.submit-button {
  width: 106px;
  height: 40px;
  margin-top: 15px;
  border-radius: 4px;
  background-color: #131622;
  border: none;
  cursor: pointer;
  outline: none;
}

.submit-button:hover {
  opacity: 0.8;
}

.button-text {
  color: #f7fafc;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #f4f4f5;
  margin-top: 50px;
}

.success-message {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: -40px;
}

.success-message-text {
  color: #004e3d;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.success-image {
  margin-right: 5px;
}

@media only screen and (max-width: 768px) {
  .root {
    width: 100%;
  }

  .container {
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
    margin-top: 50px;
  }

  .title {
    font-size: 26px;
    margin-bottom: -2%;
  }

  .subtext {
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 30px;
  }

  .textarea-form {
    height: 130px;
    -webkit-appearance: none;
  }

  .textarea-form:focus {
    outline: none;
    border: 2px solid #1a202c;
  }

  .success-message {
    margin-bottom: 0px;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .submit-button {
    width: 100%;
  }
}

body {
  margin: 0;
}

.dashboard-root {
  width: 100%;
  height: auto;
  background-color: #edf2f7;
  position: relative;
  padding-bottom: 40px;
}

/* Header Section */

.displayNone {
  display: none;
}

.dashboard-container {
  width: 900px;
  min-height: 800px;
  height: auto;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 60px;
}

.dashboard-nav {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.nav-left {
  width: auto;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.nav-left:hover {
  opacity: 0.9;
}

.logo-text {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #131726;
  margin: 0;
  -webkit-text-decoration: solid;
          text-decoration: solid;
}

.nav-right {
  width: auto;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-column-gap: 25px;
  -webkit-column-gap: 25px;
          column-gap: 25px;
}

.nav-cancel-button {
  width: auto;
  height: auto;
  padding-right: 25px;
  border-right: 1px solid #4a5568;
}

.dashboard-upgrade-button {
  padding: 10px 20px;
  outline: none;
  border: none;
  background-color: #dfe3ec;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #131726;
}

.nav-text {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #131726;
  text-decoration: none;
  cursor: pointer;
}

.nav-text:hover {
  opacity: 0.8;
}

.margin-right {
  margin-right: 30px;
}

.dashboard-banner {
  width: 100%;
  height: auto;
  background-color: #131726;
  border-radius: 10px;
  margin-top: 30px;
  padding: 26px 30px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.dashboard-banner-title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #f7fafc;
  margin: 0;
}

.dashboard-banner-subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #cbd5e0;
  margin: 0;
  margin-top: 5px;
  line-height: 150%;
}

.dashboard-button-container {
  margin-top: 30px;
}

.button-widget {
  width: 175px;
  height: 40px;
  background-color: #f7fafc;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}
.button-widget:hover {
  opacity: 0.9;
}

.button-widget-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.button-dashboard-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.button-dashboard {
  width: 196px;
  height: 40px;
  background-color: #212936;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-left: 20px;
  cursor: pointer;
}

.button-dashboard:hover {
  opacity: 0.8;
}

.button-widget-text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
  margin-left: 5px;
}

.button-dashboard-text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #edf2f7;
  margin-left: 5px;
}

/* Dashboard Section */

.dashboard-section {
  width: 100%;
  height: auto;
  margin-top: 70px;
}

.name-container {
  width: 100%;
  height: auto;
}

.dashboard-name {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #131726;
}

.options-container {
  width: 100%;
  height: auto;
  margin-top: 70px;
}

.option {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #131726;
  cursor: pointer;
  padding-bottom: 10px;
}

.selected-option {
  border-bottom: 2px solid #131726;
}

/* Question Section */

.question-section {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

/* Footer Section */

.footer-section {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
}

.footer-container {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.left-footer-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.copyright-text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
  margin: 0;
}

.copyright-text-bottom {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
  margin: 0;
}

.copyright-container {
  display: -webkit-flex;
  display: flex;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.left-footer-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.twitter-link {
  color: #2d3748;
  text-decoration: none;
  margin-left: 3px;
}

.footer-subtext {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
  margin: 0;
}

.central-footer-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

[data-reach-dialog-content] {
  width: 600px !important;
  height: auto !important;
  padding: 0px !important;
  border-radius: 10px;
}

.modal-top-container {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #cbd5e0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.modal-top-container > img {
  margin-right: 30px;
}

.modal-bottom-container {
  padding: 40px;
  box-sizing: border-box;
}

.paywall-modal-container {
  width: 100%;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
}

.paywall-top {
  width: 100%;
  height: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-bottom: 30px;
}

.paywall-badge {
  width: auto;
  padding: 4px 15px;
  background-color: #dcecfd;
  border-radius: 30px;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #30519f;
  letter-spacing: 1px;
}

.paywall-modal-price {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 40px;
  color: #1a202c;
}

.paywall-modal-price > span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #718096;
  margin-left: 5px;
}

.paywall-modal-subtext {
  margin-top: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #718096;
  line-height: 150%;
}

.paywall-divider {
  width: 90%;
  height: 1px;
  background-color: #edf2f7;
}

.paywall-bottom {
  width: 100%;
  height: 40%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-top: 30px;
  border-top: 1px solid #edf2f7;
}

.paywall-modal-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 30px;
}

.paywall-modal-item > span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #131726;
  margin-left: 15px;
}

.paywall-bottom > button {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: #eff1f6;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
}

.paywall-bottom > button:hover {
  background-color: #131726;
  color: white;
}

.paywall-cancel-text {
  width: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
  margin-top: 20px;
}

.cancel_subscription_modal {
  width: 100%;
  height: auto;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
}

.warning_icon_wrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fee5e5;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 20px;
}

.subscription_modal_title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #131726;
  text-align: center;
}

.subscription_modal_subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #4a5568;
  margin-top: 10px;
  line-height: 150%;
  text-align: center;
}

.subscription_modal_buttonOne {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: 1px solid #cbd5e0;
  background-color: white;
  border-radius: 6px;
  margin-top: 40px;
  margin-bottom: 8px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
}

.subscription_modal_buttonOne:hover {
  background-color: #f7fafc;
}

.subscription_modal_buttonTwo {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: #e02222;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #edf2f7;
}

.subscription_modal_buttonTwo:hover {
  background-color: #c61b1a;
}

.modal-bottom-container > h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #131726;
  margin: 0;
}

.modal-bottom-container > p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #4a5568;
  margin-top: 20px;
  line-height: 150%;
}

.modal-code-container {
  width: 100%;
  height: height;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #1d2b44;
  padding: 20px 0px 20px 20px;
  box-sizing: border-box;
  overflow: auto;
  white-space: nowrap;
}

.modal-code-container::-webkit-scrollbar {
  display: none;
}

.modal-contact-support > span {
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 400;
  font-size: 16px;
  color: #4162fa;
}

.modal-contact-support:hover {
  opacity: 0.8;
}

.embed-code-line {
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
}

.code-line-number {
  margin-right: 18px;
  color: #6c7d9b;
  font-weight: 400;
}

.code-line-comment {
  color: #4fd1c5;
  font-weight: 400;
}

.code-line-bracket {
  color: #9eb3d6;
}

.code-line-script {
  color: #f56565;
  margin-right: 8px;
}

.code-line-source {
  color: #f6ad55;
}

.code-line-link {
  color: #68d391;
}

.modal-button-wrapper {
  margin-top: 40px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.modal-button-left {
  width: 49%;
  height: auto;
  padding: 10px 0px;
  background-color: white;
  outline: none;
  border: 1px solid #cbd5e0;
  border-radius: 5px;
  cursor: pointer;
}

.modal-button-left > span {
  font-family: "Inter", sans-serif;
  font-weight: 5 00;
  font-size: 14px;
  color: #1a202c;
}

.modal-button-right:hover {
  opacity: 0.9;
}

.modal-button-right {
  width: 49%;
  height: auto;
  padding: 10px 0px;
  background-color: #1a202c;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-button-left:hover {
  background-color: #f6f8fb;
}

.modal-button-right > span {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #f7fafc;
}

.crossIcon {
  cursor: pointer;
}

.crossIcon:hover {
  opacity: 0.6;
}

.deleteQuestion-modal-wrapper {
  width: 100%;
  height: auto;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #131726;
  text-align: center;
}

.deleteQuestion-modal-wrapper > span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #4a5568;
  margin-top: 10px;
  line-height: 150%;
  text-align: center;
}

.deleteQuestion-modal-buttonWrapper {
  width: 100%;
  margin-top: 40px;
}

.deleteQuestion-modal-cancelButton {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: 1px solid #cbd5e0;
  background-color: white;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
  margin-bottom: 10px;
}

.deleteQuestion-modal-cancelButton:hover {
  background-color: #f7fafc;
}

.deleteQuestion-modal-deleteButton {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: none;
  background-color: #c53030;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;
}

.deleteQuestion-modal-deleteButton:hover {
  background-color: #ea3e40;
}

@media only screen and (max-width: 768px) {
  .dashboard-container {
    width: 100%;
    padding: 30px 16px;
    box-sizing: border-box;
    min-height: 0px;
  }

  .dashboard-nav {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .nav-right {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .stamp-image {
    width: 30px;
    margin-right: 10px;
  }

  .nav-text {
    font-size: 14px;
  }

  .logo-text {
    font-size: 20px;
    font-weight: 700;
  }

  .dashboard-banner {
    height: auto;
  }

  .dashboard-banner-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .dashboard-banner-subtitle {
    font-size: 16px;
    line-height: 150%;
  }

  .dashboard-button-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .button-widget {
    width: 100%;
    margin-bottom: 15px;
  }

  .button-widget-container {
    width: 100%;
  }

  .button-widget-container > span {
    font-size: 16px;
  }

  .button-dashboard {
    width: 100%;
    margin: 0;
    -webkit-align-items: center;
            align-items: center;
  }

  .button-dashboard-container > span {
    font-size: 16px;
  }

  .footer-section {
    display: none;
  }

  [data-reach-dialog-content] {
    width: 95% !important;
    height: auto !important;
    padding: 0px !important;
    border-radius: 10px;
  }

  .modal-bottom-container {
    padding: 20px;
    box-sizing: border-box;
  }

  .nav-cancel-button {
    padding-right: 15px;
    margin-right: 15px;
  }
}

.full-container {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0px 0px 10px 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 6px -3px #cbd5e0;
}

.top-box {
  width: 100%;
  height: auto;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.badge {
  width: 95px;
  height: 20px;
  background-color: #fcf5ba;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.badge-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: #98774d;
}

.question {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.question-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #131726;
}

.textarea-answer {
  width: 100%;
  min-height: 175px;
  border: 1px solid #e4e4e7;
  outline-color: #52525b;
  border-radius: 7px;
  padding: 15px;
  resize: vertical;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #f9fafc;
}

.display-off {
  display: none;
}

.bottom-box {
  width: 100%;
  height: 30%;
  background: #f9fafc;
  border-radius: 0px 0px 10px 10px;
  padding: 0px 20px 20px 20px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.createdAt {
  width: 100px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
}

.received-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 10px;
  color: #718096;
  letter-spacing: 1px;
}

.date-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #131726;
  margin-top: 5px;
}

.answer-button-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.answer-button {
  width: 100px;
  height: 30px;
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: #131726;
  cursor: pointer;
}

.answer-button:hover {
  opacity: 0.9;
}

.cancel-button {
  width: 80px;
  height: 30px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d1d5da;
  background-color: white;
  cursor: pointer;
  margin-right: 10px;
}

.send-button {
  width: 100px;
  height: 30px;
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: #131726;
  cursor: pointer;
}

.send-icon {
  margin-right: 5px;
}

.cancel-button:hover {
  background-color: #f7fafc;
}

.cancel-button-text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #131726;
  margin-bottom: 1px;
}

.button-inside {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.button-text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #f7fafc;
  margin-bottom: 1px;
}

.pen-icon {
  margin-right: 5px;
}

.loading-wrapper {
  width: 100%;
  height: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.binIcon-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff5f5;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.binIcon-wrapper:hover {
  background-color: #fed7d7;
}

@media only screen and (max-width: 768px) {
  .textarea-answer {
    -webkit-appearance: none;
  }

  .textarea-answer:focus {
    outline: none;
    border: 2px solid #1a202c;
  }
}

.answers-full-container {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0px 0px 10px 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 6px -3px #cbd5e0;
}

.answers-top-box {
  width: 100%;
  height: auto;
  min-height: 70%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.answers-bottom-box {
  width: 100%;
  height: auto;
  min-height: 70%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.answers-badge {
  width: 74px;
  height: 20px;
  background-color: #dbf4f5;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.answers-badge-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: #3a6c73;
}

.answers-question {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.answers-question-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #131726;
  line-height: 25px;
}

.answers-answer {
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #4a5568;
  line-height: 25px;
}

.answers-bottom-box {
  width: 100%;
  height: 30%;
  background: #f9fafc;
  border-radius: 0px 0px 10px 10px;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.answers-createdAt {
  width: 100px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
}

.answers-received-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 10px;
  color: #718096;
  letter-spacing: 1px;
}

.answers-date-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #131726;
  margin-top: 5px;
}

html {
  scroll-behavior: smooth;
}

.nav-section {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #f6f8fb;
}

.nav-container {
  width: 1180px;
  height: auto;
  margin-top: 30px;
}

.nav-inner-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.nav-right {
  width: auto;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.nav-sign-wrapper {
  padding-left: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-left: 1px solid #4a5568;
}

.nav-sign-wrapper > img {
  margin-left: 5px;
  margin-top: 2px;
}

.nav-left {
  width: auto;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.nav-button {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #1a202c;
  text-decoration: none;
  cursor: pointer;
}

.nav-button:hover {
  opacity: 0.8;
}

.nav-brand-name {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1a202c;
}

.return-home {
  text-decoration: none;
}

/* Mobile Responsiveness */

@media screen and (max-width: 768px) {
  .nav-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .nav-container {
    width: 100%;
  }

  .nav-inner-container {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .nav-button {
    font-size: 14px;
  }

  .nav-brand-name {
    font-size: 20px;
    font-weight: 700;
    margin-left: 10px;
  }

  .nav-right {
    margin-top: 16px;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }

  .nav-right > a {
    margin-right: 16px;
  }

  .nav-left > img {
    width: 30px;
  }

  .nav-left {
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }
}

.hero-section {
  padding-top: 50px;
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #f6f8fb;
}

.hero-container {
  width: 1180px;
  height: auto;
  margin-top: 75px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.hero-title-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.hero-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #1a202c;
}

.hero-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #718096;
}

.hero-button-container {
  margin-top: 40px;
  display: -webkit-flex;
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.hero-button-right {
  border: none;
  outline: none;
  padding: 15px 30px;
  box-sizing: border-box;
  border-radius: 7px;
  background-color: #1a202c;
  cursor: pointer;
}

.hero-button-right > span {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #f7fafc;
  text-decoration: none;
}

.hero-button-right:hover {
  opacity: 0.9;
}

.hero-button-left > span {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #1a202c;
  text-decoration: none;
}

.hero-button-left {
  border: 1px solid #cbd5e0;
  outline: none;
  padding: 15px 30px;
  box-sizing: border-box;
  border-radius: 7px;
  background-color: #ffffff;
  cursor: pointer;
}

.hero-button-left:hover {
  background-color: #f6f8fb;
}

.hero-image {
  width: 971px;
  height: auto;
  margin-top: 70px;
  margin-bottom: -300px;
}

.image-top-container {
  width: 100%;
  height: 50px;
  background-color: #f1f2f4;
  border-radius: 10px 10px 0 0;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.menu-buttons {
  width: 10%;
  height: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: start;
          justify-content: start;
  -webkit-align-items: center;
          align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.button-red {
  width: 16px;
  height: 16px;
  background-color: #f33d3d;
  border-radius: 10px;
}

.button-yellow {
  width: 16px;
  height: 16px;
  background-color: #feb932;
  border-radius: 10px;
}

.button-green {
  width: 16px;
  height: 16px;
  background-color: #04b079;
  border-radius: 10px;
}

.search-bar {
  width: 85%;
  height: 65%;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  padding: 5px 15px;
}

.domain-text-one {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #718096;
}

.domain-text-two {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #4a5568;
}

.image-bottom-container {
  width: 100%;
  height: auto;
  border-left: 1px solid #cbd5e0;
  border-right: 1px solid #cbd5e0;
  border-bottom: 1px solid #cbd5e0;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 100px;
  background-color: white;
  box-shadow: 0px 4px 10px 0px rgba(19, 22, 34, 0);
}

.amaMobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .hero-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .hero-container {
    width: 100%;
  }

  .hero-title-container {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .hero-title {
    font-size: 30px;
    margin-bottom: 16px;
  }

  .hero-subtitle {
    font-size: 16px;
    line-height: 150%;
  }

  .hero-image {
    width: 100%;
    margin-bottom: -150px;
  }

  .image-top-container {
    height: 30px;
  }

  .search-bar {
    width: 80%;
    height: 70%;
    padding: 0 7px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 6px;
  }

  .domain-text-one {
    font-size: 10px;
  }

  .domain-text-two {
    font-size: 10px;
  }

  .menu-buttons {
    width: 15%;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }

  .button-red {
    width: 8px;
    height: 8px;
    margin-right: 6px;
  }

  .button-yellow {
    width: 8px;
    height: 8px;
    margin-right: 6px;
  }

  .button-green {
    width: 8px;
    height: 8px;
  }

  .hero-button-container {
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-row-gap: 0px;
    row-gap: 0px;
    width: 100%;
    margin-top: 32px;
  }

  .hero-button-left {
    width: 100%;
  }

  .hero-button-right {
    width: 100%;
    margin-top: 10px;
  }

  .image-bottom-container {
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 50px;
  }

  .amaDesktop {
    display: none;
  }

  .amaMobile {
    display: block;
  }
}

.hiw-section {
  margin-top: 450px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.hiw-container {
  width: 1180px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.hiw-title-container {
  width: auto;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.hiw-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #1a202c;
}

.hiw-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
}

.feature {
  width: 100%;
  margin-top: 130px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

.feature-text-container {
  max-width: 490px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.hiw-feature-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #1a202c;
}

.hiw-number {
  width: 50px;
  height: 50px;
  background-color: #1a202c;
  border-radius: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
}

.hiw-number-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 28px;
  color: #f7fafc;
}

.hiw-feature-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
  line-height: 150%;
}

.extraFeatures-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  margin-top: 20px;
}

.extraFeatures-image-container {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: hsla(220, 26%, 14%, 0.1);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.extraFeatures-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1a202c;
}

/* Mobile */

@media only screen and (max-width: 768px) {
  .hiw-section {
    margin-top: 350px;
  }

  .hiw-container {
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .hiw-title {
    margin-bottom: 20px;
  }

  .hiw-subtitle {
    text-align: center;
  }

  .feature-image-container {
    width: 100%;
  }

  .feature-image-container > img {
    width: 100%;
  }

  .feature {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 75px;
  }

  .feature-text-container {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .hiw-number {
    margin-bottom: 30px;
  }

  .hiw-feature-title {
    margin-bottom: 16px;
  }

  .hiw-first {
    margin-bottom: 10px;
    margin-top: 40px;
  }

  .hiw-second {
    margin-bottom: 40px;
  }

  .hiw-swapped {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .hiw-space-feature {
    margin-bottom: 40px;
  }

  .extraFeatures-text {
    margin-left: 10px;
  }
}

.embed-section {
  width: 100%;
  height: auto;
  margin-top: 150px;
  background-color: #f6f8fb;
  border-top: 1px solid #cbd5e0;
  border-bottom: 1px solid #cbd5e0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 80px 0;
}

.embed-container {
  width: 1180px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-row-gap: 50px;
  row-gap: 50px;
}

.embed-title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.embed-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #1a202c;
}

.embed-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
}

.embed-code-fullContainer {
  width: 600px;
  height: auto;
  background-color: #1d2b44;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 20px 0px 20px 20px;
  box-sizing: border-box;
}

.embed-code-container {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  position: relative;
  margin-top: -10px;
}

.outside-overflow {
  width: 100%;
  height: 20px;
  position: relative;
}

.clip-wrapper {
  position: absolute;
  right: 10px;
  top: -10px;
  width: 30px;
  height: 30px;

  border-radius: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.clip-wrapper:hover {
  background-color: #283d58;
  cursor: pointer;
}

.embed-code-container::-webkit-scrollbar {
  display: none;
}

.embed-code-line {
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
}
.code-line-number {
  margin-right: 18px;
  color: #6c7d9b;
  font-weight: 400;
}

.code-line-comment {
  color: #4fd1c5;
  font-weight: 400;
}

.code-line-bracket {
  color: #9eb3d6;
}

.code-line-script {
  color: #f56565;
  margin-right: 8px;
}

.code-line-source {
  color: #f6ad55;
}

.code-line-link {
  color: #68d391;
}

.left-margin {
  margin-left: 15px;
}

.code-line-function {
  color: #ecc94b;
}

.code-line-key {
  color: #9eb3d6;
  margin-right: 5px;
}

.embed-demo-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.embed-demo-button:hover {
  opacity: 0.8;
}

.embed-demo-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1a202c;
  cursor: pointer;
  text-decoration: none;
}

.arrowIcon {
  margin-top: 3px;
}

@media only screen and (max-width: 768px) {
  .embed-section {
    width: 100%;
    padding: 60px 16px;
    box-sizing: border-box;
  }

  .embed-container {
    width: 100%;
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .embed-title-container {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .embed-title {
    text-align: center;
    margin-bottom: 16px;
  }

  .embed-subtitle {
    margin-bottom: 40px;
    text-align: center;
  }

  .embed-code-fullContainer {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 20px;
  }
}

.pricing-section {
  width: 100%;
  height: auto;
  margin-top: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.pricing-container {
  width: 1180px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-row-gap: 75px;
  row-gap: 75px;
}

.pricing-title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.pricing-title-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #1a202c;
}

.pricing-subtitle-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
}

.pricing-grid {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-column-gap: 80px;
  -webkit-column-gap: 80px;
          column-gap: 80px;
}

.pricing-element {
  width: auto;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.pricing-element-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.pricing-offer-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: #1a202c;
}

.pricing-offer-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
}

.pricing-buttonContainer {
  width: 100%;
  margin-top: 30px;
}

.pricing-item {
  display: -webkit-flex;
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  margin-top: 20px;
}

.pricing-item-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #1a202c;
}

.pricing-buy-button {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: #1a202c;
  cursor: pointer;
}

.pricing-buy-button:hover {
  opacity: 0.8;
}

.pricing-button-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #f7fafc;
}

.month {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #718096;
  margin-left: 5px;
}

.pricing-underBtn-text {
  width: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .pricing-section {
    margin-top: 100px;
  }

  .pricing-container {
    width: 100%;
    grid-row-gap: 0px;
    row-gap: 0px;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .pricing-grid {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 40px;
  }

  .pricing-element {
    width: 100%;
    margin-bottom: 30px;
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .pricing-title-text {
    margin-bottom: 16px;
  }

  .pricing-title-container {
    margin-bottom: 20px;
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .pricing-element-inner {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .pricing-offer-title {
    margin-bottom: 10px;
  }

  .pricing-item {
    margin-top: 30px;
  }
}

.landing-footer-section {
  margin-top: 150px;
  width: 100%;
  height: auto;
  background-color: #f6f8fb;
  border-top: 1px solid #cbd5e0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.landing-footer-container {
  width: 1180px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.landing-footer-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.landing-title-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1a202c;
}

.landing-subtitle-text {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
}

.landing-twitter-link {
  font-weight: 500;
  color: #2d3748;
  cursor: pointer;
  text-decoration: none;
  margin-left: 5px;
}

.landing-footer-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.landing-top-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.landing-footer-year {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
}

@media only screen and (max-width: 768px) {
  body {
    width: 100%;
  }

  .landing-footer-section {
    margin-top: 100px;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .landing-footer-container {
    width: 100%;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .landing-footer-left {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .landing-footer-left > h3 {
    margin-bottom: 5px;
  }

  .landing-footer-center {
    display: none;
  }

  .landing-footer-right {
    margin-bottom: 10px;
  }
}

.success-section {
  width: 100vw;
  height: 100vh;
  background-color: #f6f8fb;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.success-container {
  width: 500px;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.success-container > img {
  margin-bottom: 30px;
}

.success-page-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: #1a202c;
  margin-bottom: 10px;
}

.success-page-subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #4a5568;
  margin-bottom: 30px;
  text-align: center;
  line-height: 150%;
}

.success-container > a {
  width: 100%;
}

.success-page-button {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: none;
  background-color: #eef6f1;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #1e543e;
}

.success-page-button:hover {
  background-color: #4ba979;
  color: white;
}

@media only screen and (max-width: 768px) {
  .success-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .success-container {
    width: 100%;
  }

  .success-page-title {
    text-align: center;
  }
}

.error-section {
  width: 100vw;
  height: 100vh;
  background-color: #f6f8fb;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.error-container {
  width: 500px;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.error-container > img {
  margin-bottom: 30px;
}

.error-page-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: #1a202c;
  margin-bottom: 10px;
}

.error-page-subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #4a5568;
  margin-bottom: 30px;
  text-align: center;
  line-height: 150%;
}

.error-container > a {
  width: 100%;
}

.error-page-button {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  outline: none;
  border: none;
  background-color: #eff1f6;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #131726;
}

.error-page-button:hover {
  background-color: #131726;
  color: white;
}

@media only screen and (max-width: 768px) {
  .error-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .error-container {
    width: 100%;
  }

  .error-page-title {
    text-align: center;
  }
}

.login-section {
  width: 100vw;
  height: 100vh;
  background-color: #f6f8fb;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.login-form-container {
  width: 500px;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 10px;
}

.login-form-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #1a202c;
}

.login-form-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
  line-height: 150%;
  margin-top: 10px;
}

.login-form-input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 40px;
}

.login-email-label {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #4a5568;
  margin-bottom: 8px;
}

.login-password-label {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #4a5568;
  margin-top: 20px;
  margin-bottom: 8px;
}

.login-email-input {
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  outline: none;
  padding: 0px 10px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.login-password-input {
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  outline: none;
  padding: 0px 10px;
}

.login-password-input::-webkit-input-placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.login-password-input:-ms-input-placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.login-password-input::placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.login-password-input:focus {
  border: 2px solid #1a202c;
}

.login-email-input:focus {
  border: 2px solid #1a202c;
}

.login-email-input::-webkit-input-placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.login-email-input:-ms-input-placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.login-email-input::placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.login-submit-button {
  margin-top: 30px;
  outline: none;
  border: none;
  width: 100%;
  background-color: #1a202c;
  border-radius: 5px;
  padding: 14px 0px;
  cursor: pointer;
}

.login-submit-button:hover {
  opacity: 0.9;
}

.login-submit-button > span {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #f7fafc;
  margin-top: 40px;
}

.login-register-button {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.login-register-button > p {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
}

.register-button-redirect {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1a202c;
  text-decoration: underline;
  margin-left: 5px;
}

.register-button-redirect:hover {
  opacity: 0.8;
}

.login-error-container {
  margin-top: 30px;
  margin-bottom: -10px;
  width: 100%;
  height: auto;
  min-height: 40px;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: #fdf0f0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  border-radius: 4px;
  border: 1px solid #9f2d2d;
}

.login-error-container > h3 {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #9f2d2d;
  line-height: 150%;
}

@media only screen and (max-width: 768px) {
  .login-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .login-container {
    width: 100%;
  }

  .login-form-container {
    width: 100%;
  }

  .login-error-container > img {
    margin-right: 5px;
  }

  .login-email-input {
    -webkit-appearance: none;
  }

  .login-password-input {
    -webkit-appearance: none;
  }
}

.register-section {
  width: 100vw;
  height: 100vh;
  background-color: #f6f8fb;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.register-form-container {
  width: 500px;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 10px;
}

.register-form-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #1a202c;
}

.register-form-subtitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #718096;
  line-height: 150%;
  margin-top: 10px;
}

.register-form-input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 40px;
}

.register-email-label {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #4a5568;
  margin-bottom: 8px;
}

.register-password-label {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #4a5568;
  margin-top: 20px;
  margin-bottom: 8px;
}

.register-email-input {
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  outline: none;
  padding: 0px 10px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.register-password-input {
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  outline: none;
  padding: 0px 10px;
}

.register-password-input::-webkit-input-placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.register-password-input:-ms-input-placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.register-password-input::placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.register-password-input:focus {
  border: 2px solid #1a202c;
}

.register-email-input:focus {
  border: 2px solid #1a202c;
}

.register-email-input::-webkit-input-placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.register-email-input:-ms-input-placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.register-email-input::placeholder {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}

.register-submit-button {
  margin-top: 30px;
  outline: none;
  border: none;
  width: 100%;
  background-color: #1a202c;
  border-radius: 5px;
  padding: 14px 0px;
  cursor: pointer;
}

.register-submit-button:hover {
  opacity: 0.9;
}

.register-submit-button > span {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #f7fafc;
  margin-top: 40px;
}

.register-login-button {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.register-login-button > p {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #718096;
}

.login-button-redirect {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1a202c;
  text-decoration: underline;
  margin-left: 5px;
}

.login-button-redirect:hover {
  opacity: 0.8;
}

.register-error-container {
  margin-top: 30px;
  margin-bottom: -10px;
  width: 100%;
  height: auto;
  min-height: 40px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fdf0f0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  border-radius: 4px;
  border: 1px solid #9f2d2d;
}

.register-error-container > h3 {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #9f2d2d;
  line-height: 150%;
}

@media only screen and (max-width: 768px) {
  .register-section {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .register-form-container {
    width: 100%;
  }

  .register-error-container > img {
    margin-right: 5px;
  }

  .register-email-input {
    -webkit-appearance: none;
  }

  .register-password-input {
    -webkit-appearance: none;
  }
}

